import React from "react";
import Article from "./Article";

const Articles = ({ articles, title }) => {
  return (
    <div className="w-full services bg-white">
      <div className="max-w-6xl mx-auto px-4 pt-6 pb-10 md:pb-64 text-sm">
        <h3 className="text-3xl font-bold text-dpurple nexaTextBold pb-4">
          {title}
        </h3>

        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-7">
          {articles.map((post) => {
            return <Article key={post.id} {...post} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Articles;
