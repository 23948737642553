import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";
import { graphql } from "gatsby";
import Articles from "../components/Articles";

const Index = ({ data }) => {
  const {
    allMdx: { nodes: articles },
  } = data;


  return (
    <Layout blog={true}>
      <Articles articles={articles} title="Todos los Artículos" />
    </Layout>
  );
};

export const query = graphql`
  {
    allMdx(
      filter: {frontmatter: {published: {eq: true}}}
      sort: { fields: frontmatter___date, order: DESC }, limit: 12) {
      nodes {
        frontmatter {
          title
          author
          category
          readTime
          slug
          date(formatString: "MMMM, Do YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        excerpt
      }
    }
  }
`;

export default Index;
